<template>
	<link
		href="https://cdn-images.mailchimp.com/embedcode/classic-061523.css"
		rel="stylesheet"
		type="text/css"
	/>
	<div class="mx-auto max-w-xl mt-12">
		<div id="mc_embed_shell">
			<div id="mc_embed_signup">
				<form
					action="https://impro-amsterdam.us7.list-manage.com/subscribe/post?u=86abd1a10d80fd09179442e16&amp;id=3abfdb9cda&amp;f_id=001ec4e4f0"
					method="post"
					id="mc-embedded-subscribe-form"
					name="mc-embedded-subscribe-form"
					class="validate"
					target="_blank"
				>
					<div id="mc_embed_signup_scroll">
						<h2 class="text-2xl font-bold">Join our mailing list</h2>
						<div class="indicates-required text-right mt-4">
							<span class="asterisk text-red-700">*</span> indicates required
						</div>
						<div class="mc-field-group">
							<label for="mce-EMAIL" class="inline-block mb-2">
								Email Address <span class="asterisk text-red-700">*</span>
							</label>
							<input
								type="email"
								name="EMAIL"
								class="required email block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
								id="mce-EMAIL"
								required=""
								value=""
							/>
						</div>
						<div id="mce-responses" class="clear foot">
							<div
								class="response"
								id="mce-error-response"
								style="display: none"
							></div>
							<div
								class="response"
								id="mce-success-response"
								style="display: none"
							></div>
						</div>
						<div aria-hidden="true" style="position: absolute; left: -5000px">
							<input
								type="text"
								name="b_86abd1a10d80fd09179442e16_3abfdb9cda"
								tabindex="-1"
								value=""
							/>
						</div>
						<div class="optionalParent mt-4">
							<div class="clear foot flex justify-between items-center">
								<div>
									<input
										type="submit"
										name="subscribe"
										id="mc-embedded-subscribe"
										class="button rounded-md bg-gray-400 px-8 py-2 text-sm text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										value="Subscribe"
									/>
								</div>
								<p class="w-32 lg:w-48">
									<a
										href="http://eepurl.com/idt59b"
										title="Mailchimp - email marketing made easy and fun"
										><span
											style="
												display: inline-block;
												background-color: transparent;
												border-radius: 4px;
											"
											><img
												class="refferal_badge w-full"
												src="/mailchimp_logo.jpeg"
												alt="Intuit Mailchimp" /></span
									></a>
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<style scoped>
#mc_embed_signup {
	background: #fff;
	clear: left;
	font:
		14px Helvetica,
		Arial,
		sans-serif;
}
</style>

<script lang="js">
export default {
	mounted() {
		this.addScript('https://code.jquery.com/jquery-3.7.1.min.js', () => {
			this.addScript(
				'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js',
				this.initializeMailchimp,
			)
		})
	},

	addScript(url, callback) {
		const script = document.createElement('script')
		script.src = url
		script.type = 'text/javascript'
		script.onload = callback // Call the callback once the script is loaded
		document.body.appendChild(script)
	},

	initializeMailchimp() {
		if (typeof jQuery !== 'undefined') {
			;(function ($) {
				window.fnames = new Array()
				window.ftypes = new Array()
				fnames[0] = 'EMAIL'
				ftypes[0] = 'email'
				fnames[1] = 'MMERGE1'
				ftypes[1] = 'text'
				fnames[2] = 'MMERGE2'
				ftypes[2] = 'text'
			})(jQuery)

			var $mcj = jQuery.noConflict(true)
		} else {
			console.error('jQuery is not available!')
		}
	},
}
</script>
